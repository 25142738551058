<div id="verovio" #verovio (click)="handleClick($event)"></div>
<div>
    <label>
        Bar by:&nbsp;
        <select (change)="onRefinementChange()" [(ngModel)]="stateService.scoreOptions.barlines">
          <option *ngFor="let opt of barOptions" value="{{opt[1]}}">{{opt[0].toLowerCase()}}</option>
        </select>
    </label>
    &nbsp;
    <label>
        Switch to Modern Clefs
        <input (change)="onRefinementChange()" type="checkbox" [(ngModel)]="stateService.scoreOptions.modernClefs">
    </label>
    &nbsp;
    <label>
        Add Dissonance Labels
        <input (change)="update(doc._score)" type="checkbox" [(ngModel)]="dissonanceLabels">
    </label>
</div>
<div>
    <button color=primary class="primary-button" mat-stroked-button *ngIf="!stateService.editorialMode" (click)="setEditorialMode()">
        Continue in Editorial Mode
    </button>
</div>
