<div class="toolbar-container">
    <button mat-stroked-button (click)="goToInputEditor()">
        Return to Music Input
    </button>
    <button mat-stroked-button>
        <a download type="application/xml" (click)="saveFiles($event)">
            Download MEI File(s)
        </a>
    </button>
    <button mat-stroked-button (click)="copyToClipboard()">
        Copy MEI Score to Clipboard
    </button>
</div>
