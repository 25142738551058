<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light" id="mainNav">
    <a class="navbar-brand" href="https://measuringpolyphony.org">
       <img src="assets/img/thumbnail.png" id="thumbnail">
       <div id="navTitle">Measuring Polyphony</div>
       <div id="navDescription">Digital encodings of late medieval music</div>
       <div class="clearfix"></div>
    </a>
    <div id="additionalButtons">
        <a class="additionalButtons" href="https://measuringpolyphony.org/transcriptions.html">
          Browse
        </a>
        <a class="additionalButtons" href="./">
            Transcribe
        </a>
        <a class="additionalButtons" href="https://measuringpolyphony.org">About</a>
    </div>
</nav>

<router-outlet></router-outlet>
