<form [formGroup]="staffForm" *ngIf="selected">
    <h1>Enter Music</h1>
    <fieldset>
        <label class="music-subentry">Voice:&nbsp;
            <select [(ngModel)]="selected.voice" formControlName="voice">
                <option *ngFor="let voice of voices" value="{{voice[0]}}">{{voice[1]}}</option>
            </select>
        </label>
    </fieldset>
    <h3 class="music-subentry">Select Initial Mensuration:</h3>
    <div class="flex-container">
        <fieldset>
            <legend class="mensuration">modus</legend>
            <input [(ngModel)]="selected.parent.modus" type="radio" id="modus-3" formControlName="modus" value="3"/>
            <label for="modus-3">3</label>
            &nbsp;
            <input [(ngModel)]="selected.parent.modus" type="radio" id="modus-2" formControlName="modus" value="2"/>
            <label for="modus-2">2</label>
            &nbsp;
            <input [(ngModel)]="selected.parent.modus" type="radio" id="modus-na" formControlName="modus" value="0"/>
            <label for="modus-na">N/A</label>
        </fieldset>
        <fieldset>
            <legend class="mensuration">tempus</legend>
            <input [(ngModel)]="selected.parent.tempus" type="radio" id="tempus-3" formControlName="tempus" value="3"/>
            <label for="tempus-3">3</label>
            &nbsp;
            <input [(ngModel)]="selected.parent.tempus" type="radio" id="tempus-2" formControlName="tempus" value="2"/>
            <label for="tempus-2">2</label>
            &nbsp;
            <input [(ngModel)]="selected.parent.tempus" type="radio" id="tempus-na" formControlName="tempus" value="0"/>
            <label for="tempus-na">N/A</label>
        </fieldset>
        <fieldset>
            <legend class="mensuration">prolatio</legend>
            <input [(ngModel)]="selected.parent.prolatio" type="radio" id="prolatio-3" formControlName="prolatio" value="3"/>
            <label for="prolatio-3">3</label>
            &nbsp;
            <input [(ngModel)]="selected.parent.prolatio" type="radio" id="prolatio-2" formControlName="prolatio" value="2"/>
            <label for="prolatio-2">2</label>
            &nbsp;
            <input [(ngModel)]="selected.parent.prolatio" type="radio" id="prolatio-na" formControlName="prolatio" value="0"/>
            <label for="prolatio-na">N/A</label>
        </fieldset>
    </div>
    <fieldset>
        <label class="music-subentry">Mensuration Sign:&nbsp;
            <select [(ngModel)]="selected.parent.sign" formControlName="mensursign">
                <option *ngFor="let sign of signs" value="{{sign[1]}}">{{sign[1]}}</option>
            </select>
        </label>
    </fieldset>
</form>
<div #example (click)="handleClick($event)"></div>
<div id="mensur-change" *ngIf="selected && selected.contents.m_index >= 0 && selected.contents.m_list[selected.contents.m_index].m_type == 'mensur'">
    <form [formGroup]="mensurForm">
    <fieldset>
        <legend class="mensuration">modus</legend>
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_modus" type="radio" id="modus2-3" formControlName="modus2" value="3"/>
        <label for="modus2-3">3</label>
        &nbsp;
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_modus" type="radio" id="modus2-2" formControlName="modus2" value="2"/>
        <label for="modus2-2">2</label>
        &nbsp;
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_modus" type="radio" id="modus2-na" formControlName="modus2" value="0"/>
        <label for="modus2-na">N/A</label>
    </fieldset>
    <fieldset>
        <legend class="mensuration">tempus</legend>
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_tempus" type="radio" id="tempus2-3" formControlName="tempus2" value="3"/>
        <label for="tempus2-3">3</label>
        &nbsp;
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_tempus" type="radio" id="tempus2-2" formControlName="tempus2" value="2"/>
        <label for="tempus2-2">2</label>
        &nbsp;
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_tempus" type="radio" id="tempus2-na" formControlName="tempus2" value="0"/>
        <label for="tempus2-na">N/A</label>
    </fieldset>
    <fieldset>
        <legend class="mensuration">prolatio</legend>
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_prolatio" type="radio" id="prolatio2-3" formControlName="prolatio2" value="3"/>
        <label for="prolatio2-3">3</label>
        &nbsp;
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_prolatio" type="radio" id="prolatio2-2" formControlName="prolatio2" value="2"/>
        <label for="prolatio2-2">2</label>
        &nbsp;
        <input [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_prolatio" type="radio" id="prolatio2-na" formControlName="prolatio2" value="0"/>
        <label for="prolatio2-na">N/A</label>
    </fieldset>
    <fieldset>
        <label class="music-subentry">Mensuration Sign:&nbsp;
            <select [(ngModel)]="selected.contents.m_list[selected.contents.m_index].m_sign" formControlName="mensursign2">
                <option *ngFor="let sign of signs" value="{{sign[1]}}">{{sign[1]}}</option>
            </select>
        </label>
    </fieldset>
    </form>
</div>
<div id="tenor-section" *ngIf="selected && selected.voice === 'tenor'">
    <p>
        If the piece has repetitions of the tenor that are not written out in full,
        please specify here. See "Music Input Help" for details.
    </p>
    <button class="primary-button" mat-stroked-button (click)="repTenorButton()">Set End of Repeating Tenor</button>
    &nbsp;
    <label>Total Number of Tenor Statements:&nbsp;
        <input [(ngModel)]="selected.parent.repetitions" type="number" min="1">
    </label>
</div>
<button color=primary class="primary-button" *ngIf="selected" mat-stroked-button (click)="finishStep()">
    Continue to Score Editor
</button>
