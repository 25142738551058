<div class="toolbar-container">
    <button mat-stroked-button>
        <a download type="application/xml" (click)="saveClick($event)">
            Download MEI File
        </a>
    </button>
    <button mat-stroked-button *ngIf="currentStaff">
        <a download type="text/plain" (click)="saveHumdrumClick($event)">
            Download System Humdrum
        </a>
    </button>
    <button mat-stroked-button *ngIf="currentStaff" (click)="deleteStaff()">
        Delete Staff
    </button>
</div>
