<div id="editor-container">
    <app-diva-view [iiifManifest]="iiifManifest"></app-diva-view>
    <div id="right-side">
        <mat-tab-group>
            <mat-tab label="Metadata Input" *ngIf="inputStep === 0">
                <form *ngIf="inputStep===0" [formGroup]="metadataForm" (submit)="onSetMetadata()">
                    <h1 id="metadata-header">Enter Metadata</h1>
                    <label class="metadataInput">Short title:&nbsp;
                        <input id="shortTitle" formControlName="shortTitle" required/>
                    </label><br>
                    <label class="metadataInput">Composer name:&nbsp;
                        <input id="composerName" formControlName="composerName" required/>
                    </label><br>
                    <label class="metadataInput">Notation:&nbsp;
                        <select id="notationSubtype" formControlName="notationSubtype" required>
                            <option value="Ars_antiqua">Black Mensural &mdash; Ars antiqua</option>
                            <option value="Ars_nova">Black Mensural &mdash; Ars nova</option>
                            <option value="">White Mensural</option>
                        </select>
                    </label><br>
                    <label class="metadataInput">Manuscript Siglum:&nbsp;
                        <input id="siglum" formControlName="siglum" required/>
                    </label><br>
                    <label class="metadataInput">Genre:&nbsp;
                        <select id="genre" formControlName="genre" required>
                            <option value="Motet">Motet</option>
                            <option value="Song">Song</option>
                            <option value="Conductus">Conductus</option>
                            <option value="Mass Movement">Mass Movement</option>
                            <option value="Plainchant">Plainchant</option>
                        </select>
                    </label><br>
                    <div *ngFor="let contributor of temp.controls; let i = index">
                        <ng-container [formGroup]="contributor">
                          <label class="contributor">Contributor:&nbsp;
                            <select formControlName="type" required>
                                <option value="encoder">Encoder</option>
                                <option value="proofreader">Proofreader</option>
                                <option value="editor">Editor</option>
                            </select>
                            <input formControlName="name" required/>
                          </label>
                          &nbsp;
                          <button color=secondary class="secondary-button" mat-stroked-button type="button" (click)="removeContributor(i)">Remove</button>
                        </ng-container>
                    </div>
                    <div>
                      <button color=primary class="primary-button" mat-stroked-button type="button" (click)="addContributor()">Add Contributor</button>
                    </div>
                    <br>
                    <button color=primary class="primary-button" mat-stroked-button type="submit">Continue to Music Input</button>
                </form>
            </mat-tab>
            <mat-tab label="Music Input" *ngIf="inputStep === 1">
                <mat-divider></mat-divider>
                <app-toolbar *ngIf="inputStep===1"></app-toolbar>
                <mat-divider></mat-divider>
                <app-staff-select *ngIf="inputStep===1"></app-staff-select>
            </mat-tab>
            <mat-tab label="General Help">
                <app-general-help></app-general-help>
            </mat-tab>
            <mat-tab label="Music Input Help">
                <app-music-input-help></app-music-input-help>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
