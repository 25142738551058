<div id="score-container">
    <app-score-diva-view [iiifManifest]="iiifManifest"></app-score-diva-view>
    <div id="right">
        <mat-tab-group>
            <mat-tab label="Score Editor">
                <app-score-toolbar></app-score-toolbar>
                <mat-divider></mat-divider>
                <app-score-verovio-view></app-score-verovio-view>
            </mat-tab>
            <mat-tab label="Score Editor Help">
                <app-score-editor-help></app-score-editor-help>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
